.homepagemainbox{
    animation-name: homeanimation;
    animation-duration: 2s;
    
}
@keyframes homeanimation {
    0%{
        margin-top: 300px;
    }
    100%{
        margin-top: 20px;
    }
}


.product1home{
    animation-name: product1homeanimation;
    animation-duration: 2s;
    
}
@keyframes product1homeanimation {
    0%{
        margin-top: 200px;
    }
    100%{
        margin-top: 20px;
      
    }
}



.homesectionone{
    height: 700px;
    overflow: hidden;
}

@media screen and (max-width: 600px) {
    .homesectionone{
        height: 550px;
        overflow: hidden;
    }
  }