.productBoxForPro{
    height: 65vh;
   padding-top: 100px;
    scroll-behavior:mooth;
    margin-bottom: -30px;
}


@media(max-width: 700px) {

 

    .productBoxForPro{
        height: auto;
       padding-top: 0px;
       
        margin-bottom: -0px;
    }
   
  }