.product23home{
    animation-name: product23homeanimation;
    animation-duration: 2s;
    
}
@keyframes product23homeanimation {
    0%{
        margin-top: 200px;
       
    }
    100%{
        margin-top: 20px;
    }
}