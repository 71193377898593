.contact{
    animation-name: contactanimation;
    animation-duration: 2s;
    
}
@keyframes contactanimation {
    0%{
        margin-top: 200px;
    }
    100%{
        margin-top: 20px;
    }
}