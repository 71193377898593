.Header {
    position: fixed;
    top: 0; /* Stick it to the top */
    max-height: 70px;
    width: 100vw;
    font-family: "SF Pro Display";
    display: grid;
    grid-template-areas: "logo logo nav";

    /* Cosmetics */
    background-color: #ffffff;
    box-shadow: 0px 1px 3.299999952316284px 0px #00000040;
    z-index: 90000;
    
  }
  
  .Logo {
    grid-area: logo;
    height: 70px;
    margin-left: 50px;

  }
  
  .Nav {
    display: grid;
    grid-area: nav;
    grid-template-columns: repeat(5, auto);
    align-items: center;
    justify-items: center;
    margin-right: 50px;
    
  }
  .Nav a {
    color: #69696A;
    font-size: 16px;
    font-family: "SF Pro Display";
    font-weight: 500;
    transition: 0.5s;
    text-decoration: none;
  }
  .Nav a:hover {
    transform: scale(1.1);
  }
  .Nav a:active {
    /* transform: scale(1.1); */
    color: #2A4596;

  }
  .Nav button {
    padding: 0px 16px 0px 16px;
    outline: none;
    border: none;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    height: 32px;
 
    font-family: "SF Pro Display";
  
    background-color: #2A4596;
    /* box-shadow: 0px 5px 0px 0px rgba(255, 0, 0, 0.25); */
    border-radius: 4px;
    cursor: pointer;
    transition: 70ms;
  }
  
  .Nav button:active {
    transform: translateY(3px);
    box-shadow: 0px 2px 0px 0px rgba(255, 0, 0, 0.25);
  }
  
  .Burger {
    display: none;
    grid-area: burger;
    margin: 0 20px 0 0;
    padding: 0;
    justify-self: end;
    font-size: 40px;
    border: none;
    background: none;
    outline: none;
    transition: 0.1s;
  }
  .Burger:active {
    transform: scale(1.2);
  }
  
  @media (max-width: 700px) {
    .Header {
      grid-template-areas: "logo burger" "nav nav";
      z-index: 90000;
      position: fixed;
    }
    .Nav {
      grid-template-rows: repeat(4, auto);
      grid-template-columns: none;
      grid-row-gap: 20px;
      width: 95%; 
      margin-top: 1px;
    z-index: 90000;
    position: fixed;
      justify-content: start;
      text-align: start;
  justify-items: start;
    align-items: start;
      padding: 30px 0 30px 40px;
    
      background: rgba(255, 255, 255, 1);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      /* border-bottom-left-radius: 10px; */
      /* border-bottom-right-radius: 10px; */
    }
    .Burger {
      display: inline;
    }
  }
  
  .NavAnimation-enter {
    opacity: 0;
    transform: scale(0.5);
  }
  .NavAnimation-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 350ms, transform 350ms;
  }
  .NavAnimation-exit {
    opacity: 1;
  }
  .NavAnimation-exit-active {
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 350ms, transform 350ms;
  }
  