
    .mainboxkey{
        width: 95vw;
        height: 600px;
        /* border: 1px solid red; */
        /* border: 1px solid red; */
        display: grid;
        grid-template-columns: 2fr 2fr 2fr;
        /* overflow: scroll; */
        margin: 20px 40px 20px 40px;
       /* margin: auto; */
       padding-left: 3vw;
        margin-top: 20px;
        grid-gap: 10px;
        animation-name: centerbox;
  animation-duration: 4s;
  animation-iteration-count: 1;
 

    }
    @keyframes centerbox {
        
        0%{
            margin-top: 90vh;
            /* position: fixed; */
            .left{
                /* display: none; */
                visibility: hidden;
            }
        }
        50%{
            margin-top: 0px;
            /* position: fixed; */

        }
        100%{
            margin-top: 0px;
            /* position: fixed; */

           
        }
    }
   
    .center{
        overflow: visible;
        /* position: fixed; */
        width: 32vw;
        height: 290px;
        margin-left: -3vw;
        margin-top: 130px;
        background-color: #FFF;
        z-index: 10;
        align-content: center;
        text-align: center;
        border-radius: 18px;
        border: 0.5px solid #D9D9D9;
    }


    .left{
     
        display: grid;
    grid-template-columns: 1fr;
        grid-gap: 200px;
        width: 30vw;
      
        
         
    }
    .firstboxanimation{
 
        animation-name: example;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        ;
        /* animation-fill-mode: forwards; */
      border-radius: 39.51px;
        height: 160px;
  animation-duration: 4s;
  background-color: rgba(245, 251, 255, 1);
  justify-content: center;
  display: flex;
  align-items: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  gap:10px;
  padding-left: 20px;
  padding-right: 20px;

  width: 30vw;
  animation-iteration-count: 1;
  /* animation-delay: 4s; */
  

    }
  
    @keyframes example {
     
  0%   {
        margin-right: -30px;
     visibility: hidden;}
       
  50%   {margin-top: 200px;
       margin-left: 400px;
       width: 400px;
    visibility: hidden}

  /* 25%   {margin-top: 130px; position: fixed; width: 200px;height: 70px;}
  50%   {margin-top: 80px; position: fixed; width: 270px;height: 100px;}
  75%   {margin-top: 50px; position: fixed; width: 300px;height: 130px;}
  */
  /* 50%   {margin-top: 30px;position: fixed;} */
  100%   {margin-top: 0px;   width: 400px;}



  /* 25%  {background-color: yellow;} */
 
}
    .secondboxanimation{
      background-color: rgba(245, 251, 255, 1);
      justify-content: center;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        ;
      display: flex;
      border-radius: 39.51px;
      gap:20px;
      padding-left: 20px;
      padding-right: 20px;
      align-items: center;
        animation-name: examplep;
  animation-duration: 4s;
  width: 30vw;
  height: 160px;
  /* animation-delay: 4s; */

    }

    @keyframes examplep {
   

  0%   {
    margin-right: -30px;
 visibility: hidden;}
   
50%   {margin-top: -50vh;
   margin-left: 400px;
   width: 30vw;
visibility: hidden}
  100%   {margin-top: 0px; width: 32vw;}

}
    .right{
        margin-left:-5vw;
        display: grid;
    grid-template-columns: 1fr;
        grid-gap: 200px;
        width: 30vw;


    }
    .threeanimationbox{
      background-color: rgba(245, 251, 255, 1);
      justify-content: center;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        ;
      display: flex;
      border-radius: 39.51px;
      gap:30px;
      padding-left: 20px;
      padding-right: 20px;
      align-items: center;
        animation-name: examplepf;
  animation-duration: 4s;
  height: 160px;
  width: 30vw;

  /* animation-delay: 4s; */

    }

    @keyframes examplepf {
        0%   {margin-top: 120px;margin-left: -430px;visibility: hidden;}
        50%   {margin-top: 120px;margin-left: -430px;visibility: hidden;}

  /* 25%   {margin-top: 200px;position: fixed;}
  50%   {margin-top: 220px;position: fixed;} */
  100%   {margin-top: 0px}

}
    .fouranimationbox{
      background-color: rgba(245, 251, 255, 1);
      justify-content: center;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        ;
      display: flex;
      border-radius: 39.51px;
      gap:20px;
      padding-left: 20px;
      padding-right: 20px;
      align-items: center;
        animation-name: exampleps;
  animation-duration: 4s;
  height: 160px;

  width: 30vw;

  /* animation-delay: 4s; */

    }

    @keyframes exampleps {
       
  0%   {
    margin-left: -430px;
 visibility: hidden;}
   
50%   {margin-top: -50vh;
   margin-left: -430px;
   width: 32vw;
visibility: hidden}
  100%   {margin-top: 0px; width: 32vw}

}
    
    
